<template lang="pug">
Publisher(v-if="initialized", ref="publisher", :isSysCheck="isSysCheck", :isBreakout="isBreakout", :token="activeToken")
</template>
<script lang="ts">
import config from "@/config";
import {
  ConnectionEvent,
  Group,
  StreamCreatedEvent,
  StreamDestroyedEvent,
  StreamPropertyChangedEvent,
  ToggleVideoUser,
  VideoDisabledWarningEvent,
  VideoDisabledWarningLiftedEvent,
  VideoElementCreatedEvent,
  VideoEnabledChangedEvent,
} from "@/types";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import Publisher from "./Publisher.vue";
import OT, { OTError, SubscriberStats } from "@opentok/client";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import authorization from "@/auth/authorization";
import VonageUtil from "./vonageUtil";
import {
  AddSubscriber,
  ApplyUserVideoFilter,
  RemoveSubscriber,
  SetPublisher,
  SetSession,
  VideoContainerId,
} from "@/types/vonage";
import { SetMediaState } from "@/types/media";
import employeeLogo from "@/assets/images/logos/Crucial-C.svg";
import { EventContentModel } from "@cruciallearning/puddle";
import routes from "@/services/routes";
import { genericErrors } from "./ErrorHandler";
import log from "./DebugLogger";

@Component({
  components: {
    Publisher,
  },
  computed: {
    ...mapState([
      "streamSessionId",
      "streamToken",
      "breakoutVonageToken",
      "breakoutSessionId",
      "selectedVideoId",
      "sessionToggle",
    ]),
    ...mapState("EventModule", ["event"]),
    ...mapState("UsersModule", ["users"]),
    ...mapState("VonageModule", ["subscribers", "session", "publisher"]),
    ...mapGetters("BreakoutsModule", ["getActiveBreakoutSelf"]),
  },
  methods: {
    ...mapMutations("MediaModule", ["setMediaState"]),
    ...mapMutations("UsersModule", ["toggleVideoUser", "clearAllVideoUsers"]),
    ...mapActions("VonageModule", [
      "setPublisher",
      "setSession",
      "addSubscriber",
      "removeSubscriber",
      "applyUserVideoFilter",
    ]),
  },
})
export default class Session extends Vue {
  @Prop({ required: false, default: false }) isSysCheck!: boolean;

  private readonly session!: OT.Session | null;
  private readonly subscribers!: OT.Subscriber[];
  private readonly publisher!: OT.Publisher | null;

  private readonly event!: EventContentModel;
  private readonly getActiveBreakoutSelf!: Group | undefined;

  private readonly streamSessionId!: string;
  private readonly streamToken!: string;
  private readonly breakoutVonageToken!: string;
  private readonly breakoutSessionId!: string;

  private readonly setMediaState!: SetMediaState;
  private readonly toggleVideoUser!: ToggleVideoUser;
  private readonly applyUserVideoFilter!: ApplyUserVideoFilter;

  private readonly setPublisher!: SetPublisher;
  private readonly addSubscriber!: AddSubscriber;
  private readonly removeSubscriber!: RemoveSubscriber;
  private readonly setSession!: SetSession;

  private muteIconSrc!: string;
  initialized = false;

  async mounted(): Promise<void> {
    this.muteIconSrc = await VonageUtil.getMuteIconSrc();
    this.getToken();
  }

  beforeDestroy(): void {
    console.log("Destroyed component -- disconnecting from session");
    this.session?.disconnect();
  }
  async init(): Promise<void> {
    this.initialized = false;
    OT.on("exception", (event) => {
      log("Vonage exception", event);
    });
    const sessionLocal = OT.initSession(config.streamKey, this.activeSessionId);
    sessionLocal.on({
      connectionCreated: (event: ConnectionEvent) => {
        log("Connection Created", event);
      },
      connectionDestroyed: (event: ConnectionEvent) => {
        log("Connection Destroyed", event);
      },
      sessionConnected: (event: unknown) => {
        log("Session Connected", event);
      },
      sessionDisconnected: (event: { reason: string }) => {
        log("Session Disconnected", event);
      },
      sessionReconnected: (event: unknown) => {
        log("Session Reconnected", event);
      },
      sessionReconnecting: (event: unknown) => {
        log("Session Reconnecting...", event);
      },
      streamCreated: (event: StreamCreatedEvent) => {
        //This is fired when other users connect to you
        log("Stream Created", event);
        const idx = this.subscribers.findIndex((e) => e.stream?.streamId === event.stream?.streamId);
        if (idx == -1 && event.stream) {
          const subscriber = this.initSubscriber(event.stream);
          if (subscriber) this.addSubscriber(subscriber);
        }
      },
      streamDestroyed: (event: StreamDestroyedEvent) => {
        log("Stream Destroyed", event);
        if (event.stream) {
          const securityId = JSON.parse(event.stream.connection.data).securityId;
          this.toggleVideoUser(securityId);
        }
        this.removeSubscriber(event.stream);
      },
      streamPropertyChanged: (event: StreamPropertyChangedEvent) => {
        log("Stream Property Changed", event);
        const securityId = JSON.parse(event.stream.connection.data).securityId;
        if (securityId && securityId == this.$auth.authUser.id) {
          if (event.changedProperty === "hasVideo") {
            this.setMediaState({ hasVideo: event.newValue });
          }
          if (event.changedProperty === "hasAudio") {
            this.setMediaState({ hasAudio: event.newValue });
          }
        }
        if (event.changedProperty === "hasAudio") {
          VonageUtil.setMuteIconState(securityId, !event.newValue);
        }

        if (event.changedProperty === "hasVideo") {
          this.toggleVideoUser(securityId);
        }
      },
      muteForced: (event: unknown) => {
        log("Mute forced", event);
      },
    });
    this.setSession(sessionLocal);
    this.initialized = true;
  }
  initSubscriber(stream: OT.Stream): OT.Subscriber | undefined {
    log("init subscriber", stream);
    let id = "";
    if (this.isBreakout) {
      id = "breakout-users";
    } else {
      let group = VideoContainerId.LEARNERS;
      const securityId = JSON.parse(stream.connection.data).securityId;
      if (authorization.isTrainer(securityId, this.event)) {
        group = VideoContainerId.TRAINERS;
      }
      if (authorization.isModerator(securityId, this.event) || authorization.isObserver(securityId, this.event)) {
        group = VideoContainerId.MODERATORS;
      }
      id = `${group}-video-container`;
    }
    const options: OT.SubscriberProperties = {
      insertDefaultUI: true,
      showControls: true,
      insertMode: "append",
      fitMode: "cover",
      style: {
        audioBlockedDisplayMode: "off",
        audioLevelDisplayMode: "off",
        buttonDisplayMode: "off",
        nameDisplayMode: "off",
        videoDisabledDisplayMode: "auto",
      },
    };

    const _subscriber = this.session?.subscribe(stream, id, options, genericErrors);
    _subscriber?.on({
      videoElementCreated: (event: VideoElementCreatedEvent) => {
        log("Subscriber Video Element Created", event);
        const subscriber = event.target as OT.Subscriber;
        if (!subscriber.stream) return;
        const securityId = JSON.parse(subscriber.stream.connection.data).securityId;
        const node = VonageUtil.userNameNode(securityId, event.target.stream?.connection.data);
        const mutedNode = VonageUtil.mutedIndicatorNode(event.target.stream?.name, this.muteIconSrc);
        if (node) {
          event.target.element?.append(node);
          event.target.element?.append(mutedNode);
          VonageUtil.setMuteIconState(securityId, !event.target.stream?.hasAudio);
        }
        if (authorization.isObserver(securityId)) {
          const iconNode = VonageUtil.employeeIndicatorNode(securityId, employeeLogo);
          event.target.element?.append(iconNode);
        }
        if (!this.isBreakout) {
          if (subscriber.element) {
            const parsedSecurityId = VonageUtil.userClass(securityId);
            subscriber.element.classList.add("user-video", "speaker", `user-video-${parsedSecurityId}`);
            if (authorization.isRegisteredLearner(securityId, this.event)) {
              if (this.$auth.ADMIN) {
                const menu = VonageUtil.menuIconNode(securityId);
                subscriber.element.append(menu);
              } else {
                const chatIcon = VonageUtil.chatIconNode(securityId);
                subscriber.element.append(chatIcon);
              }
            } else {
              const chatIcon = VonageUtil.chatIconNode(securityId);
              subscriber.element.append(chatIcon);
            }
          }
          if (event.target.element) {
            event.target.element.style.width = "45%";
            event.target.element.style.height = "0";
            event.target.element.style.paddingBottom = "35%";
          }
        } else {
          if (event.target.element) {
            const initialNode = VonageUtil.hiddenVideoNode(event.target.stream?.connection.data);
            if (initialNode) {
              event.target.element.append(initialNode);
            }
          }
        }
        if (subscriber.stream?.hasVideo) {
          this.toggleVideoUser(securityId);
          this.applyUserVideoFilter();
        }
        // disable the context menu
        if (event.element) {
          event.element.oncontextmenu = () => {
            return false;
          };
        }
      },

      streamCreated: (event: StreamCreatedEvent): void => {
        log("Subscriber Stream Created", event);
      },

      destroyed: (event: unknown): void => {
        log("Subscriber destroyed", event);
      },

      connected: (event: ConnectionEvent): void => {
        log("Subscriber connected", event);
      },

      disconnected: (event: ConnectionEvent): void => {
        log("Subscriber disconnected", event);
      },

      videoEnabled: (event: VideoEnabledChangedEvent): void => {
        log("Subscriber video enabled", event);
      },

      videoDisabled: (event: VideoEnabledChangedEvent): void => {
        log("Subscriber video disabled", event);
      },

      videoDisableWarning: (event: VideoDisabledWarningEvent): void => {
        log("Subscriber video disable warning", event);
        if (event.target.stream) {
          const subId = JSON.parse(event.target.stream.connection.data).securityId;
          const sub = this.subscribers.find((sub) => {
            if (sub.stream) {
              const securityId = JSON.parse(sub.stream?.connection.data).securityId;
              return securityId === subId;
            }
          });
          const frameRate = sub?.stream?.frameRate;
          if (frameRate && frameRate > 1) {
            if (sub) {
              console.log("reducing subscriber frame rate from " + frameRate);
              sub.getStats((error?: OTError, stats?: SubscriberStats): void => {
                if (stats) console.warn(stats);
              });
              sub.setPreferredFrameRate(frameRate - 1);
            }
          }
        }
      },

      videoDisableWarningLifted: (event: VideoDisabledWarningLiftedEvent): void => {
        log("Subscriber video disable warning lifted", event);
      },

      audioBlocked: (event: unknown): void => {
        log("Subscriber blocked", event);
      },

      audioUnblocked: (event: unknown): void => {
        log("Subscriber audio unblocked", event);
      },
    });
    return _subscriber;
  }
  @Watch("getActiveBreakoutSelf")
  getToken(): void {
    if (!this.getActiveBreakoutSelf) {
      this.$socket.send({ type: routes.VONAGE_TOKEN });
    } else {
      this.$socket.send({ type: routes.BREAKOUT_TOKEN });
    }
  }
  @Watch("streamSessionId")
  @Watch("breakoutSessionId")
  streamReady(sessionId: string): void {
    if (!sessionId) return;
    this.subscribers.forEach((sub) => {
      this.session?.unsubscribe(sub);
    });
    if (this.publisher) this.session?.unpublish(this.publisher);
    this.session?.disconnect();
    this.setSession(null);
    this.setPublisher(null);
    this.init();
  }

  get isBreakout(): boolean {
    return !!this.breakoutSessionId && !!this.breakoutVonageToken;
  }

  get activeSessionId(): string {
    return this.isBreakout ? this.breakoutSessionId : this.streamSessionId;
  }

  get activeToken(): string {
    return this.isBreakout ? this.breakoutVonageToken : this.streamToken;
  }
}
</script>
