import { ActionContext, Module } from "vuex";
import { ActiveTimer, Timer, TimerDirection, TimerSettingsModel, TimerState, TimerStateModel } from "@/types/timer";
import { sockets } from "@/services/socket_api";

const TimerModule: Module<TimerState, unknown> = {
  namespaced: true,
  state(): TimerState {
    return {
      timers: [],
      active: null,
      muted: false,
      sound: "ting",
      timestamp: 0,
      completed: false,
    };
  },
  mutations: {
    setTimerState(state: TimerState, stateChanges: Partial<TimerState>) {
      Object.assign(state, stateChanges);
    },
    setActiveState(state: TimerState, stateChanges: Partial<ActiveTimer>) {
      Object.assign(state, {
        ...state,
        active: {
          ...state.active,
          ...stateChanges,
        },
      });
    },
  },
  actions: {
    clearActiveTimer({ state }) {
      if (state.active && state.active.localTimer) {
        clearInterval(state.active.localTimer);
      }
      state.active = null;
    },
    getLocalTimers({ state }) {
      const timers = localStorage.getItem("localTimers");
      if (timers) {
        state.timers = JSON.parse(timers);
      }
    },
    addTimer({ state }, timer: Timer): void {
      const duplicate: Timer | undefined = state.timers?.find(
        (duplicateTimer) => duplicateTimer.seconds === timer.seconds && duplicateTimer.dir === timer.dir
      );
      if (!duplicate) {
        const newTimer: Timer = {
          seconds: timer.seconds,
          dir: timer.dir,
        };
        state.timers.push(newTimer);
        state.timers.sort((a, b) => (a.seconds > b.seconds ? 1 : -1));
        localStorage.setItem("localTimers", JSON.stringify(state.timers));
      }
    },
    deleteTimer({ state }: ActionContext<TimerState, unknown>, timer: Timer): void {
      for (let i = 0; i < state.timers.length; i++) {
        const checkTimer = state.timers[i];
        if (checkTimer.dir == timer.dir && checkTimer.seconds == timer.seconds) {
          state.timers.splice(i, 1);
          localStorage.setItem("localTimers", JSON.stringify(state.timers));
          break;
        }
      }
    },
    setTickInterval({ commit, state, dispatch }): void {
      if (state.active && !state.active.dir) {
        commit("setTimerState", { active: undefined });
        return;
      }
      const tick = () =>
        setInterval(() => {
          const intervalTime = Date.now() - state.timestamp;
          commit("setTimerState", { timestamp: Date.now() });
          if (state.active?.dir === "DOWN") {
            const time = Math.round(state.active.time - intervalTime);
            if (time <= 0) {
              dispatch("clearActiveTimer");
              commit("setTimerState", { completed: true });
              // dispatch("BreakoutsModule/stopBreakout", { root: true });
            } else {
              commit("setActiveState", { time });
            }
          } else if (state.active?.dir === "UP") {
            const time = Math.round(state.active.time + intervalTime);
            if (time >= state.active.initial) {
              dispatch("clearActiveTimer");
              commit("setTimerState", { completed: true });
              //  dispatch("BreakoutsModule/stopBreakout", { root: true });
            } else {
              commit("setActiveState", { time });
            }
          }
        }, 100);
      commit("setActiveState", { localTimer: tick() });
    },
    runTimer({ commit, dispatch, state }, timer: TimerStateModel): void {
      commit("setTimerState", {
        timestamp: Date.now(),
        active: {
          ...state.active,
          dir: timer.direction,
          time: timer.time,
          initial: timer.length,
          isPaused: false,
        },
        completed: false,
      });
      dispatch("setTickInterval");
    },
    pauseTimer({ state, commit }, timer: TimerStateModel): void {
      commit("setActiveState", { isPaused: true });
      if (state.active?.localTimer) clearInterval(state.active.localTimer);
      commit("setTimerState", {
        active: {
          ...state.active,
          localTimer: undefined,
          time: timer.time,
          initial: timer.time,
        },
      });
    },
    resumeTimer({ dispatch, commit }, timer: TimerStateModel): void {
      commit("setActiveState", { isPaused: false, time: timer.time, intial: timer.length });
      commit("setTimerState", { timestamp: Date.now() });
      dispatch("setTickInterval");
    },
    stopTimer({ dispatch }): void {
      dispatch("clearActiveTimer");
    },
    timerJoin({ dispatch }, timer: TimerSettingsModel): void {
      if (timer.timerValue && timer.timerInitial) {
        if (timer.timerDirection === "DOWN" && timer.timerValue < 0) return;
        if (timer.timerDirection === "UP" && timer.timerValue > timer.timerInitial) return;
      }
      dispatch("runTimer", {
        direction: timer.timerDirection,
        time: timer.timerValue,
        running: timer.timerRunning,
        length: timer.timerInitial,
      });
    },
    setMute(_, mute: boolean): void {
      sockets.timer.mute(mute);
    },
    setTimerSound(_, sound: string): void {
      sockets.timer.setSound(sound);
    },
    timerStart(_, event: { at: number; dir: TimerDirection }) {
      sockets.timer.start(event.at, event.dir);
    },
    timerStop() {
      sockets.timer.stop();
    },
    timerResume() {
      sockets.timer.resume();
    },
    timerPause() {
      sockets.timer.pause();
    },
  },
};

export default TimerModule;
