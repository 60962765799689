//Store
export interface VonageState {
  publisher: OT.Publisher | null;
  subscribers: OT.Subscriber[];
  session: OT.Session | null;
}

//METHODS
export interface SetPublisher {
  (publisher: OT.Publisher | null): void;
}
export interface SetVonageState {
  (changes: Partial<VonageState>): void;
}
export interface SetSession {
  (session: OT.Session | null): void;
}
export interface AddSubscriber {
  (subscriber: OT.Subscriber): void;
}
export interface RemoveSubscriber {
  (subscriber?: OT.Stream): void;
}

export interface RefreshPublisherSettings {
  (): void;
}
export interface ApplyUserVideoFilter {
  (publisher?: OT.Publisher | null): void;
}
// ENUMS
export enum VideoContainerId {
  TRAINERS = "trainers",
  LEARNERS = "learners",
  MODERATORS = "moderators",
}

export enum VideoStatusClass {
  HAS_MENU_ICON = "user-video-has-menu",
  HAS_CHAT_ICON = "user-video-has-chat",
}
