import { sockets } from "@/services/socket_api";
import { OTError } from "@opentok/client";

const publishErrors = (error?: OTError) => {
  if (error) {
    switch (error.name) {
      case "OT_NOT_CONNECTED":
        sockets?.remoteLogger?.logMessage("Publishing your video failed. You are not connected to the internet.");
        break;
      case "OT_CREATE_PEER_CONNECTION_FAILED":
        sockets?.remoteLogger?.logMessage("Publishing your video failed. This could be due to a restrictive firewall.");
        break;
      case "OT_INVALID_VIDEO_SOURCE":
        sockets?.remoteLogger?.logMessage("The videoSourceId or deviceId is invalid.");
        break;
      case "OT_SET_VIDEO_SOURCE_FAILURE":
        sockets?.remoteLogger.logMessage("Publisher is not using a camera input.");
        break;
      case "OT_NOT_SUPPORTED":
        sockets?.remoteLogger.logMessage(`User's browser does not support video filters.`);
        break;
      default:
        sockets?.remoteLogger?.logMessage(
          "An unknown error occurred while trying to publish your video or audio. Please try again later."
        );
    }
  }
};
const genericErrors = (error?: OTError) => {
  if (error) {
    console.error(error);
    sockets?.remoteLogger?.logMessage(JSON.stringify(error));
  }
};
export { genericErrors, publishErrors };
