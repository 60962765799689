<template lang="pug">
#toggle-model-container
  .toggle-icons
    Icon.toggle-model-slide.primary-toggle-icon(
      :value="iconValue",
      :class="[modelToggle, disableToggleClass]",
      height=23,
      width=25,
      @click="toggleShowModel",
      @mouseover="showToolTip = true",
      @mouseleave="showToolTip = false"
    )

    Icon.toggle-model-slide.alt-toggle-icon(
      v-if="getAltModelUrl",
      :value="altIconValue",
      :class="[altModelToggle, disableToggleClass]",
      height=23,
      width=25,
      @click="toggleShowAltModel",
      @mouseover="showAltToolTip = true",
      @mouseleave="showAltToolTip = false"
    )
  ToolTip(
    target="#toggle-model-container .primary-toggle-icon",
    dataName="toggle-model-tooltip",
    :display="primaryToolTipMessage",
    :toggle="showToolTip"
  )
  ToolTip(
    v-if="getAltModelUrl",
    target="#toggle-model-container  .alt-toggle-icon",
    dataName="toggle-model-tooltip",
    :display="altToolTipMessage",
    :toggle="showAltToolTip"
  )
</template>

<script lang="ts">
import { Lesson } from "@/types/course";
import { Icon } from "@cruciallearning/puddle/components";
import { BaseToolTip as ToolTip } from "@cruciallearning/puddle";
import { Vue, Component } from "vue-property-decorator";
import { mapActions, mapGetters, mapState } from "vuex";

@Component({
  components: { Icon, ToolTip },
  computed: {
    ...mapState("CourseModule", ["showModel", "showAltModel"]),
    ...mapGetters("CourseModule", ["getLesson", "getSlideIndex", "getAltModelUrl"]),
  },
  methods: {
    ...mapActions("CourseModule", ["toggleShowModel", "toggleShowAltModel"]),
  },
})
export default class ModelToggle extends Vue {
  private readonly showModel!: boolean;
  private readonly showAltModel!: boolean;

  private showToolTip = false;
  private showAltToolTip = false;
  private readonly getSlideIndex!: number;
  private readonly getLesson!: Lesson | undefined;

  get modelToggle(): string {
    return this.classValue(false);
  }

  get altModelToggle(): string {
    return this.classValue(true);
  }

  get classValue(): (isAlt: boolean) => string {
    return (isAlt: boolean) => {
      const value = isAlt ? this.showAltModel : this.showModel;
      return value ? "model-slide-enabled-color" : "model-slide-disabled-color";
    };
  }

  get iconValue(): string {
    return this.showModel ? "Show Model" : "Hide Model";
  }

  get primaryToolTipMessage(): string {
    return this.tooltipMessage(false);
  }

  get altToolTipMessage(): string {
    return this.tooltipMessage(true);
  }

  get altIconValue(): string {
    return this.showAltModel ? "Show Alt Model" : "Hide Alt Model";
  }

  get tooltipMessage(): (isAlt: boolean) => string {
    return (isAlt: boolean) => {
      const value = isAlt ? this.showAltModel : this.showModel;
      return value ? "Hide Course Model" : "Show Course Model";
    };
  }

  get disableToggleClass(): string {
    if (this.getLesson?.slides[this.getSlideIndex].frames[1]) {
      if (this.getLesson?.slides[this.getSlideIndex].frames[1].video) {
        return "model-slide-disabled-video";
      }
    }
    return "";
  }
}
</script>

<style lang="scss">
#toggle-model-container {
  flex: 1;
  width: 100%;
}
.toggle-icons {
  float: right;
}
.toggle-model-slide {
  margin: 0 0.25rem;
}
.toggle-model-slide:hover {
  background-color: var(--gray-10);
  border-radius: 50%;
  cursor: pointer;
}
.model-slide-disabled-color > svg > path,
.model-slide-disabled-color > svg > g > path {
  fill: var(--red) !important;
}
.model-slide-enabled-color > svg > path,
.model-slide-enabled-color > svg > g > path {
  fill: var(--success-50) !important;
}
.model-slide-disabled-video {
  pointer-events: none;
}
.model-slide-disabled-video > svg > path,
.model-slide-disabled-video > svg > g > path {
  fill: var(--gray-50) !important;
}
</style>
