<template lang="pug">
.main-stage-container.front-stage.layout-grid(:class="[columnClass]")
  Feedback(v-if="!isPractice")
  Breakout(v-if="isBreakout")
  Content(v-else)
</template>
<script lang="ts">
import { Group } from "@/types";
import { Vue, Component } from "vue-property-decorator";
import { mapGetters, mapState } from "vuex";
import { default as Breakout } from "../Breakout/BaseBreakout.vue";
import { default as Content } from "@/views/Content.vue";
import { EventContentModel } from "@cruciallearning/puddle";
import Feedback from "./Feedback.vue";
@Component({
  components: {
    Breakout,
    Content,
    Feedback,
  },
  computed: {
    ...mapState("EventModule", ["event"]),
    ...mapGetters("BreakoutsModule", ["getActiveBreakoutSelf"]),
    ...mapGetters("GridModule", ["adminCenterWidth", "learnerCenterWidth"]),
  },
})
export default class MainStage extends Vue {
  private readonly getActiveBreakoutSelf!: Group | undefined;

  private readonly adminCenterWidth!: number;
  private readonly learnerCenterWidth!: number;

  private readonly event!: EventContentModel;

  get columnClass(): string {
    const centerWidth = this.$auth.ADMIN ? this.adminCenterWidth : this.learnerCenterWidth;
    return `cell-${centerWidth}`;
  }
  get isBreakout(): boolean {
    return !!this.getActiveBreakoutSelf;
  }
  get isPractice(): boolean {
    return this.event?.practice || false;
  }
}
</script>
<style scoped lang="scss">
.main-stage-container {
  position: relative;
}
</style>
